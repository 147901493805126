import logo from '../assets/Logo.png';
export const FooterData = {
  logo: logo,
  addresses: ['6C3C+QF4, Alakamisy Ambohidratrimo', 'Antananarivo 105, Madagascar'],
  phone: '+261 (34) 49 801 37',
  departments: [
    'Accueil Triage Urgence',
    'Médecine',
    'Pédiatrie',
    'Chirurgue',
    'Réanimation',
    'More',
  ]
};

export const atu_url =['atu/atu_accueil2.jpg','atu/atu_accueil4.jpg','atu/atu_ambulance.jpg'];
export const mtc_url =['chinois/mtc_image1.jpeg','chinois/mtc_image3.JPG'];
export const ped_url=['pediatrie/ped2.jpg','pediatrie/ped3.jpg','pediatrie/ped4.jpg','pediatrie/ped6.jpg','pediatrie/ped7.jpg','pediatrie/ped8.jpg'];
export const chir_url=['chirurgie/bloc_operatoire.png','chirurgie/chirurgie_tumorale_adulte.png','chirurgie/bloc.png',];
export const labo_url=['labo/laboratoire2.jpg','labo/laboratoire4.jpg','labo/laboratoire3.jpg','labo/laboratoire6.jpg'];
export const rea_url=['rea/rea2.jpg','rea/rea5.jpg','rea/rea4.jpg'];
export const imagerie_url=['imagerie/imagerie4.jpg','imagerie/imagerie5.jpg','imagerie/imagerie7.jpg','imagerie/imagerie9.jpg']
export const anapth_url=['anapath/anapath2.jpg','anapath/anapath3.jpg','anapath/anapath8.jpg','anapath/anaphat1.jpg'];
export const mpr_url=['mpr/mpr_appareil.jpg','mpr/mpr_appareil2.jpg','mpr/appareil_mpr4.jpg'];
export const med_url=['medecine/medecine4.jpg','medecine/medecine5.jpg'];
export const pharmacie_url=['pharmacie/pharmacie1.jpg','pharmacie/pharmacie_2.jpg'];
export const tete_cou_url=['tete_cou/stomatologie_appareil1.jpg','tete_cou/ophtalmo2.jpg','tete_cou/stomatologie1.jpg','tete_cou/ophtalmo4.jpg','tete_cou/ophtalmo5.jpg','tete_cou/ophtalmo6.jpg','tete_cou/ophtalmo7.jpg'];
export const image_carousel=['actu_26_03.jpg','actu_18_03.jpg','actu_21_02.jpg','actu_04_11_2.jpg','actu_04_11.jpg', 'actu_30_05.jpg', 'actu_23_05.jpg', 'chu_anosiala_drone.png', 'chu_anosiala_gp.png', 'Labo.png', 'ophtalmo.JPG', 'stomatologie.JPG'];
export const video_carousel=['video/directeur.mp4','video/laboratoire.mp4','video/stomatologie.mp4'];